<template>
  <div class="bgStyle">
    <div class="title">
      <a-row :gutter="16">
        <a-col :span="10">角色权限</a-col>
        <a-col :span="11" style="text-align: right">
          <a-button type="primary" @click="addInfo">新增 </a-button></a-col
        >
      </a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="datasource"
      class="table"
      rowKey="name"
      :loading="loading"
    >
      <template slot="operation" slot-scope="text, record">
        <a-button
          type="primary"
          v-if="record.name !== '超级管理员'"
          @click="editInfo(record)"
          >编辑
        </a-button>
      </template>
    </a-table>
  </div>
</template>
<script>
/** 角色列 */
const columns = [
  {
    title: "角色名称",
    dataIndex: "name",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "成员",
    dataIndex: "memberList",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

import { getRolesList } from "@/api/roles.js";
export default {
  data() {
    return {
      datasource: [], //角色列表数据源
      columns, //角色列表列
      loading: false,
    };
  },
  /** 组件生命周期创建前事件 */
  created() {
    this.loading = true;
    getRolesList()
      .then((res) => {
        this.datasource = [];
        res.items.forEach((item) => {
          var data = {
            id: item.id,
            name: item.name,
            description: item.description,
            memberList: item.memberList.toString(),
          };
          this.datasource.push(data);
        });
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$message.error("获取角色列表查询异常");
      });
  },
  methods: {
    addInfo() {
      this.$router.push({
        name: "editRole",
        params: {
          visible: true,
          param: {
            name: "",
            description: "",
          },
        },
      });
    },

    /**
     * 编辑角色跳转角色编辑界面
     * @param {obj} reconds 当前行数据
     */
    editInfo(reconds) {
      this.$router.push({
        name: "editRole",
        params: {
          visible: false,
          param: reconds,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
</style>
